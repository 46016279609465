import { UseQueryOptions } from '@tanstack/react-query';
import { defaultReactQueryParams } from '../../utils/request';
import { useIdentifier } from '../global/useIdentifier';
import { ListQuery } from '../../types/request';
import { ListResult } from '../../types/response';
import { useQueryPortalServiceApi } from '../global/usePortalServiceApi';
import { AgentUser } from '../../pages/Users/types';

export type UserUserAgentStatusProps = ListQuery & {
  user_ids?: string;
  division_ids?: string;
  os?: string | null;
  os_versions?: string;
  go2_versions?: string;
};
export function useUserAgentStatus<T = AgentUser>(
  params: UserUserAgentStatusProps,
  reactQueryParams?: UseQueryOptions,
) {
  const { identifiers, queryParams } = useIdentifier<UserUserAgentStatusProps>({
    params,
    baseIdentifier: 'useUserAgentStatus',
    identifierKeys: [
      'user_ids',
      'division_ids',
      'os',
      'os_versions',
      'go2_versions',
    ],
    queryParamKeys: [
      'user_ids',
      'division_ids',
      'os',
      'os_versions',
      'go2_versions',
    ],
  });

  return useQueryPortalServiceApi<UserUserAgentStatusProps, ListResult<T>>(
    identifiers,
    '/go2-agent/user-status',
    queryParams,
    {
      ...defaultReactQueryParams,
      ...reactQueryParams,
    },
  );
}
