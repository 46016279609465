import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  useLocation,
  useParams,
  Location,
  useNavigate,
} from 'react-router-dom';
import moment, { Moment } from 'moment';
import { Box } from '@mui/material';

import DocumentTitle from '../../components/DocumentTitle';
import WindowScrollTop from '../../components/WindowScrollTop';
import AppBreadcrumbs from '../../components/AppBreadcrumbs';
import MainContainer from '../../components/MainContainer';
import UserMetadataView from './UserMetadata';
import UserTabs, {
  TabButtonParamItem,
  TabButtonParamItemNames,
} from './UserTabs';
import UserActivityLogs from './UserActivityLogs';
import UserSessionLogs from './UserSessionLogs';
import UserTimeWorked from './UserTimeWorked';
import UserSnapshots from './UserSnapshots';
import UserOnlineMeetings from './UserOnlineMeetings';
import UserKeystrokes from './UserKeystrokes';
import UserNetworkMonitoring from './UserNetworkMonitoring';
import UserAudioRecordings from './UserAudioRecordings';
import UserFilters from './UserFilters';
import ActivityChart from '../Activities/ActivityChart';

import { AgentUser } from '../Users/types';
import { stripToFormatterWord, toTitleCase } from '../../utils/string';
import { useUserProvider } from '../../providers/user/user';
import { UserMetadata } from '../../types/profile';
import { getUserDisplayName } from '../../utils/user';
import { useInfinite } from '../../hooks/global/useInfinite';
import { ActivityApplicationItem } from '../Activities/types';
import {
  useActivities,
  UseActivitiesProps,
  useUserActivityPercentage,
} from '../../hooks/go2-agent/activities';
import { useKeystrokes } from '../../hooks/go2-agent/keystroke';
import { getWeekRangeStartsInMonday } from '../../utils/date';
import { useUserSystemInfo } from '../../hooks/go2-agent/system-info';
import { fetchStartDownloadToCSV } from '../../utils/download';
import { getConfigWithAuthorization } from '../../services/base';
import { guessTimezone } from '../../utils/timezone';
import { useAuthProvider } from '../../providers/auth/auth';
import { toURL } from '../../utils/url';
import { useTimezone } from '../../components/Util/Timezone';
import UserTimeline from './UserTimeline/UserTimeline';
import { UserDetailFilterParams } from './UserTimeline/_types';
import { useTimeTrackerWorklogs } from '../../hooks/time-tracker/worklogs';

type UserDetailProps = {};

type UserDetailParams = {
  userId: string;
  tabName: TabButtonParamItemNames;
};

type UserDetailLocation = Location<null | {
  item: AgentUser;
  user: UserMetadata;
}>;

const UserDetail = (props: UserDetailProps) => {
  const navigate = useNavigate();
  const timezone = useTimezone();
  const { userId, tabName } = useParams<UserDetailParams>();
  const { state, pathname } = useLocation() as UserDetailLocation;
  const { users, getUser, setUniqueIdsToFetch } = useUserProvider();
  const { getTokenSilently } = useAuthProvider();

  let item: AgentUser | null = state?.item ?? null;
  let user = getUser('user_id', userId) ?? state?.user;

  // Filters --------------------------------------------------
  const [filterParams, setFilterParams] = useState<UserDetailFilterParams>(
    () => ({
      start_date: getWeekRangeStartsInMonday(moment()).start,
      end_date: getWeekRangeStartsInMonday(moment()).end,
    }),
  );
  const updateFilterParams = useCallback(
    (params: Partial<UserDetailFilterParams>) => {
      setFilterParams((state) => ({
        ...state,
        ...params,
      }));
    },
    [setFilterParams],
  );

  // Tabs ---------------------------------------------------------------------
  const [tabIndexName, setTabIndexName] = useState<TabButtonParamItemNames>(
    tabName ?? 'activity',
  );
  const onTabIndexHandleChange = useCallback(
    (tabParams: TabButtonParamItem, index: number) => {
      setTabIndexName(tabParams.name);
      navigate(`/users/${userId}/${tabParams.name}`, {
        state: {
          item,
          user,
        },
      });
    },
    [userId, user, item, setTabIndexName, navigate],
  );

  // System Info ---------------------------------------------------------------
  const { data: userSystemInfo } = useUserSystemInfo(
    { user_id: userId! },
    { enabled: !!userId },
  );

  // Activities ----------------------------------------------------------------
  const activityLimit = 100;
  const infiniteActivities = useInfinite<
    ActivityApplicationItem,
    UseActivitiesProps
  >({
    isFlatResult: false,
    skipFetchOnInit: true,
    useQuery: useActivities,
    queryParams: {
      start_date: filterParams.start_date.format(),
      end_date: filterParams.end_date.format(),
      user_ids: userId,
      limit: activityLimit,
    },
  });

  // Keystrokes ----------------------------------------------------------------
  const keystrokeLimit = 100;
  const infiniteKeystrokes = useInfinite<any, any>({
    isFlatResult: false,
    skipFetchOnInit: true,
    useQuery: useKeystrokes,
    queryParams: {
      start_date: filterParams.start_date.format(),
      end_date: filterParams.end_date.format(),
      user_ids: userId,
      limit: keystrokeLimit,
    },
  });

  // Charts --------------------------------------------------------------------
  const activityChartParams = useMemo(
    () => ({
      start_date: filterParams.start_date.format('YYYY-MM-DD'),
      end_date: filterParams.end_date.format('YYYY-MM-DD'),
      division: [],
      user_id: [userId?.toString()],
      timezone,
    }),
    [timezone, userId, filterParams.start_date, filterParams.end_date],
  );

  // User Timeline Worklogs ----------------------------------------------------
  const [selectedFilteredDate, setSelectedFilteredDate] = useState<Moment>(() =>
    filterParams.start_date.clone(),
  );
  const startEndDateParams = {
    start_date: selectedFilteredDate
      .clone()
      .subtract(1, 'day')
      .startOf('day')
      .utc()
      .format(),
    end_date: selectedFilteredDate
      .clone()
      .add(1, 'day')
      .endOf('day')
      .utc()
      .format(),
  };
  const { data: timeTrackerWorklogsDataSource } = useTimeTrackerWorklogs(
    {
      ...startEndDateParams,
      timezone,
      limit: 200,
      users: userId?.toString(),
    },
    {
      // Refetch the interval every 4 minutes, 1 minute less between sync time on Go2 desktop
      // refetchInterval: 240 * 1000,
      // refetchOnWindowFocus: true,
      enabled: !!selectedFilteredDate,
    },
  );
  const prevNextSelectedFilteredDate = useCallback(
    (action: 'next' | 'previous') => {
      setSelectedFilteredDate((d) =>
        d.clone()[action === 'next' ? 'add' : 'subtract'](1, 'day'),
      );
    },
    [setSelectedFilteredDate],
  );
  /**
   * Filtering the percentage data to only allow on memo
   */
  const timeTrackerWorklogsData = useMemo(() => {
    let items = timeTrackerWorklogsDataSource?.results ?? [];
    let ymdSelected = selectedFilteredDate.format('YYYY-MM-DD');
    items = items.filter((item) => {
      let startLocal = moment.utc(item.start).local();
      let endLocal = moment.utc(item.end).local();
      // Check if the interval start/end local is same as selected date
      if (
        startLocal.format('YYYY-MM-DD') === ymdSelected ||
        endLocal.format('YYYY-MM-DD') === ymdSelected
      ) {
        return true;
      }
      return false;
    });
    return items;
  }, [timeTrackerWorklogsDataSource, selectedFilteredDate]);

  // User Activity Percentage --------------------------------------------------
  const { data: userActivityPercentageDataSource } = useUserActivityPercentage({
    ...startEndDateParams,
    user_ids: userId?.toString(),
    limit: 1000,
  });
  /**
   * Filtering the percentage data to only allow on memo
   */
  const userActivityPercentageData = useMemo(() => {
    let items = userActivityPercentageDataSource ?? [];
    let ymdSelected = selectedFilteredDate.format('YYYY-MM-DD');
    items = items.filter((item) => {
      let intervalStartLocal = moment.utc(item.interval_start).local();
      let intervalEndLocal = moment.utc(item.interval_end).local();
      // Check if the interval start/end local is same as selected date
      if (
        intervalStartLocal.format('YYYY-MM-DD') === ymdSelected ||
        intervalEndLocal.format('YYYY-MM-DD') === ymdSelected
      ) {
        return true;
      }
      return false;
    });
    return items;
  }, [userActivityPercentageDataSource, selectedFilteredDate]);

  const [isDownloading, setIsDownloading] = useState(false);
  const handleOnDownloadClick = useCallback(async () => {
    if (tabName !== 'activity' && tabName !== 'keystrokes') return;

    let url = '';
    let filename = '';
    switch (tabName) {
      case 'activity': {
        filename = `${userId}_activity_${filterParams.start_date.format(
          'YYYY-MM-DD',
        )}_${filterParams.end_date.format('YYYY-MM-DD')}`;
        url = toURL(
          `${process.env.REACT_APP_PORTAL_SERVICE_URL}/api/go2-agent/activity/download`,
          {
            start_date: filterParams.start_date.format(),
            end_date: filterParams.end_date.format(),
            user_ids: userId,
          },
        );
        break;
      }
      case 'keystrokes': {
        filename = `${userId}_keystroke_${filterParams.start_date.format(
          'YYYY-MM-DD',
        )}_${filterParams.end_date.format('YYYY-MM-DD')}`;
        url = toURL(
          `${process.env.REACT_APP_PORTAL_SERVICE_URL}/api/go2-agent/keystroke/download`,
          {
            start_date: filterParams.start_date.format(),
            end_date: filterParams.end_date.format(),
            user_ids: userId,
          },
        );
        break;
      }
      default:
        break;
    }

    try {
      setIsDownloading(true);
      await fetchStartDownloadToCSV(
        url,
        {
          headers: {
            ...getConfigWithAuthorization(await getTokenSilently()).headers,
            ['x-timezone']: guessTimezone(), // eslint-disable-line
          },
        },
        {
          filename,
        },
      );
    } finally {
      setIsDownloading(false);
    }
  }, [userId, tabName, setIsDownloading, filterParams, getTokenSilently]);

  /**
   * When filter changes, it resets the activities
   */
  useEffect(() => {
    if (!user) return;

    if (tabName === 'activity') {
      infiniteActivities.reset({
        emptyList: true,
      });
    } else if (tabName === 'keystrokes') {
      infiniteKeystrokes.reset({
        emptyList: true,
      });
    }
    // eslint-disable-next-line
  }, [filterParams, tabName, user]);

  /**
   * Set's the active tab index when it changes
   */
  useEffect(() => {
    tabName && tabIndexName !== tabName && setTabIndexName(tabName);
  }, [pathname, tabName, tabIndexName, setTabIndexName]);

  /**
   * Fetches the user's metadata
   */
  useEffect(() => {
    if (user) return;

    setUniqueIdsToFetch({
      user_ids: [userId],
    });
  }, [userId, user, users, setUniqueIdsToFetch]);

  /**
   * When the main control date range is changed.
   * Let's reset the selected date
   */
  useEffect(() => {
    setSelectedFilteredDate(filterParams.start_date.clone());
  }, [filterParams.start_date, filterParams.end_date, setSelectedFilteredDate]);

  return (
    <>
      <DocumentTitle
        title={toTitleCase(stripToFormatterWord(tabIndexName))}
        trailingTitle='Users'
      />
      <WindowScrollTop deps={[userId]} />
      <MainContainer sx={{ maxWidth: undefined }}>
        <AppBreadcrumbs
          items={[
            {
              id: 'users',
              label: 'Users',
              path: '/users',
            },
            {
              id: (user ? user.id : userId)?.toString() || 'unknown',
              label: user ? getUserDisplayName(user).fullName : userId,
              path: user ? `/users/${userId}` : '',
            },
          ]}
        />
        <Box display='flex' alignItems='stretch' gap={4}>
          <UserMetadataView
            item={item}
            user={user}
            userSystemInfo={userSystemInfo}
          />
          <Box flex={1} width={0}>
            <UserTabs
              tabIndexName={tabIndexName}
              handleOnTabChange={onTabIndexHandleChange}
            >
              <UserFilters
                withDownload={
                  tabName === 'activity' || tabName === 'keystrokes'
                }
                isDownloading={isDownloading}
                filterParams={filterParams}
                updateFilterParams={updateFilterParams}
                handleOnDownloadClick={handleOnDownloadClick}
              />
              <Box gap={3} display='flex' flexWrap='wrap' px={3} mt={-2}>
                <ActivityChart
                  title='Apps/Domains'
                  metabaseProps={{
                    url: '/embed/question/',
                    key: 'question',
                    id: 334,
                    params: activityChartParams,
                  }}
                />
                <ActivityChart
                  title='Keystrokes'
                  metabaseProps={{
                    url: '/embed/question/',
                    key: 'question',
                    // id: 338,
                    id: 339,
                    params: activityChartParams,
                  }}
                />
                <ActivityChart
                  title='Login Sessions'
                  metabaseProps={{
                    url: '/embed/question/',
                    key: 'question',
                    id: 340,
                    params: activityChartParams,
                  }}
                />
              </Box>
              <br />
              <UserTimeline
                activityPercentageItems={userActivityPercentageData}
                worklogItems={timeTrackerWorklogsData}
                filterParams={filterParams}
                selectedFilteredDate={selectedFilteredDate}
                prevNextSelectedFilteredDate={prevNextSelectedFilteredDate}
              />
              <br />
              {tabIndexName === 'activity' && (
                <UserActivityLogs
                  items={infiniteActivities.data}
                  isLoading={infiniteActivities.isLoading}
                />
              )}
              {tabIndexName === 'session-log' && <UserSessionLogs />}
              {tabIndexName === 'time-worked' && <UserTimeWorked />}
              {tabIndexName === 'snapshots' && <UserSnapshots />}
              {tabIndexName === 'online-meetings' && <UserOnlineMeetings />}
              {tabIndexName === 'keystrokes' && (
                <UserKeystrokes
                  items={infiniteKeystrokes.data}
                  isLoading={infiniteKeystrokes.isLoading}
                />
              )}
              {tabIndexName === 'network-monitoring' && (
                <UserNetworkMonitoring />
              )}
              {tabIndexName === 'audio' && <UserAudioRecordings />}
              {/* {tabIndexName === 'activity' && <UserActivityLogs />}
              {tabIndexName === 'snapshots' && <UserSnapshots />}
              {tabIndexName === 'keystrokes' && <UserKeystrokes />}
              {tabIndexName === 'audio' && <UserAudioRecordings />} */}
            </UserTabs>
          </Box>
        </Box>
      </MainContainer>
    </>
  );
};

export default UserDetail;
