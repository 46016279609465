import { Box, Divider, Stack, Typography } from '@mui/material';
import moment, { Moment } from 'moment';
import React, { CSSProperties, useRef, useState } from 'react';
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';

import { useAppProvider } from '../../../providers/app/app';
import ActivityStack from './ActivityStack';
import KeystrokesStack from './KeystrokesStack';
import WorklogsStack from './WorklogsStack';
import { useWindowDimension } from '../../../hooks/utils/window';
import { UserDetailFilterParams } from './_types';
import { M3IconButton } from '../../../components/M3/M3Button';
import { TimeTrackerWorklogItem } from '../../../types/time-tracker-worklogs';
import { BiActivityIntervalItem } from '../../../types/activity';

type Props = {
  withNavigationButtons?: boolean;
  activityItems: any[];
  keystrokeItems: any[];
  worklogItems: TimeTrackerWorklogItem[];
  activityPercentageItems: BiActivityIntervalItem[];
  filterParams: UserDetailFilterParams;
  selectedFilteredDate: Moment;
  prevNextSelectedFilteredDate: (action: 'next' | 'previous') => void;
};

const TimelineBlock = ({
  activityItems,
  keystrokeItems,
  withNavigationButtons = true,
  worklogItems,
  activityPercentageItems,
  filterParams,
  selectedFilteredDate,
  prevNextSelectedFilteredDate,
}: Props) => {
  const { isDarkMode } = useAppProvider();
  const [hours] = useState(() => new Array(25).fill(null).map((_, i) => i));
  let dividerStyle: CSSProperties = {
    width: 2,
    height: 10,
    background: isDarkMode
      ? 'var(--md-ref-palette-neutral20)'
      : 'var(--md-ref-palette-neutral90)',
  };
  const periodContainerRef = useRef<HTMLDivElement | null>(null);
  const dimensions = useWindowDimension();

  return (
    <Box
      flex={1}
      style={{
        paddingLeft: 24,
        paddingRight: 24,
        paddingBottom: 12,
        position: 'relative',
      }}
    >
      <Stack
        direction='row'
        justifyContent='space-between'
        style={{
          paddingBottom: 10,
          marginLeft: -10,
          marginRight: -10,
          userSelect: 'none',
        }}
      >
        <Typography
          gap={1}
          display='flex'
          alignItems='center'
          component='div'
          fontSize={withNavigationButtons ? 16 : 13}
          fontWeight={500}
          style={{ opacity: withNavigationButtons ? 1 : 0.5 }}
        >
          {withNavigationButtons && (
            <M3IconButton
              disabled={selectedFilteredDate
                .clone()
                .startOf('day')
                .isSameOrBefore(filterParams.start_date.clone().startOf('day'))}
              onClick={() => prevNextSelectedFilteredDate('previous')}
            >
              <KeyboardBackspaceOutlinedIcon />
            </M3IconButton>
          )}
          {selectedFilteredDate.clone().format('MMM D, YYYY')}
        </Typography>
        <Typography
          gap={1}
          display='flex'
          alignItems='center'
          component='div'
          fontSize={withNavigationButtons ? 16 : 13}
          fontWeight={500}
          style={{ opacity: withNavigationButtons ? 1 : 0.5 }}
        >
          {selectedFilteredDate.clone().add(1, 'day').format('MMM D, YYYY')}
          {withNavigationButtons && (
            <M3IconButton
              disabled={selectedFilteredDate
                .clone()
                .add(1, 'day')
                .startOf('day')
                .isSameOrAfter(filterParams.end_date.clone().startOf('day'))}
              onClick={() => prevNextSelectedFilteredDate('next')}
            >
              <KeyboardBackspaceOutlinedIcon
                style={{
                  transform: 'rotateZ(180deg)',
                }}
              />
            </M3IconButton>
          )}
        </Typography>
      </Stack>
      <Box style={{ marginLeft: 40, marginRight: 24 }}>
        <Box style={{ position: 'relative' }}>
          <Box
            gap={1}
            display='flex'
            flexDirection='column'
            style={{
              left: 0,
              right: 0,
              bottom: 0,
              height: 80,
            }}
          >
            <ActivityStack
              dimensions={dimensions}
              items={activityPercentageItems}
              selectedFilteredDate={selectedFilteredDate}
            />
            {/* <KeystrokesStack dimensions={dimensions} items={keystrokeItems} /> */}
            <WorklogsStack
              dimensions={dimensions}
              items={worklogItems}
              selectedFilteredDate={selectedFilteredDate}
            />
          </Box>
          <Divider style={{ borderWidth: 1 }} />
          <Stack
            ref={periodContainerRef}
            direction='row'
            style={{
              height: 20,
              width: '100%',
              position: 'relative',
            }}
          >
            {hours.map((h, i, arr) => {
              let width = periodContainerRef.current?.clientWidth ?? 0;
              let per = width / (arr.length - 1);
              let posX = per * i;

              return (
                <Typography
                  key={h}
                  fontSize={11}
                  component='div'
                  display='flex'
                  flexDirection='column'
                  alignItems='flex-start'
                  style={{
                    top: 0,
                    left: posX,
                    position: 'absolute',
                  }}
                >
                  <Box style={dividerStyle}></Box>
                  <span
                    style={{
                      left: -19,
                      width: 40,
                      position: 'relative',
                      textAlign: 'center',
                    }}
                  >
                    {i % 2 !== 0
                      ? null
                      : moment().startOf('day').hour(h).format('h a')}
                  </span>
                </Typography>
              );
            })}
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};

export default TimelineBlock;
