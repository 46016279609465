import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { Box } from '@mui/material';

import AppNavigationBar from './components/AppNavigationBar';
import Error from './pages/Error';
import AppDrawer from './components/AppDrawer';
import FileVersioningNotice from './components/FileVersioningNotice';
import ErrorUnauthorizedAccess from './pages/ErrorUnauthorizedAccess';

import { useCurrentProfile } from './hooks/profile';
import { useAppProvider } from './providers/app/app';

type Props = {
  children?: any;
};
type DrawerPage = any;

type PathSegments = DrawerPage[];
/**
 * Put all your components here that needs to be called immediately globally
 * to the app, after initialization
 */
function Home({ children }: Props) {
  const { isDrawerOpen, drawerWidth, withNavRail } = useAppProvider();
  const { error, data: currentProfile } = useCurrentProfile();
  const noAppDrawer = !withNavRail;
  const { pathname } = useLocation();

  const renderDrawer = () => {
    // if (noAppDrawer) return null;

    return <AppDrawer />;
  };

  /*
  if (!!error) {
    return (
      <Error
        title='User not found'
        message={<>This user no longer exist. Contact your administrator.</>}
      />
    );
  }
  */

  if (
    !currentProfile?.roles.some(
      (r) => r === 'agent_admin' || r === 'developer' || r === 'superuser',
    )
  ) {
    return (
      <>
        {pathname !== '/unauthorized' && <Navigate to='/unauthorized' />}
        <ErrorUnauthorizedAccess />
      </>
    );
  }

  return (
    <>
      {pathname === '/unauthorized' && <Navigate to='/users' />}
      <FileVersioningNotice />
      <AppNavigationBar noAppDrawer={noAppDrawer} />
      <Box
        display='flex'
        sx={{
          flex: 1,
          /*
          ...(withNavRail
            ? {}
            : {
                transition: `transform ${
                  !isDrawerOpen ? '325ms' : '225ms'
                } cubic-bezier(0, 0, 0.2, 1) 0ms`,
                transform: `translateX(${
                  !isDrawerOpen ? '0' : `${drawerWidth}px`
                })`,
              }),
          */
        }}
      >
        {renderDrawer()}
        <Outlet />
      </Box>
    </>
  );
}

export default Home;
