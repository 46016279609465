import React from 'react';
import {
  Box,
  CircularProgress,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import PowerOutlinedIcon from '@mui/icons-material/PowerOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';

import { ReactRenderElement } from '../../types/types';
import { M3Button, M3IconButton } from '../../components/M3/M3Button';
import MetaPanel from '../../components/Panels/MetaPanel';

type MetadataProps = {
  totalUsers: number;
  totalOnline: number;
  isDownloading: boolean;
  isRefreshing: boolean;
  handleOnDownloadClick: () => void;
  handleOnRefreshClick: () => void;
};

const Metadata = ({
  totalOnline,
  totalUsers,
  isDownloading,
  isRefreshing,
  handleOnDownloadClick,
  handleOnRefreshClick,
}: MetadataProps) => {
  return (
    <Stack
      direction='row'
      alignItems='flex-start'
      justifyContent='space-between'
      gap={2}
      mb={3}
    >
      <Stack flex={1} direction='row' alignItems='center' gap={4} mt={2}>
        {/* <MetaPanel
          label='Online'
          value={totalOnline}
          icon={
            <PowerOutlinedIcon
              style={{
                fontSize: 30,
                color: 'green',
              }}
            />
          }
        /> */}
        <MetaPanel
          label='Total'
          value={totalUsers}
          icon={
            <PersonOutlineOutlinedIcon
              style={{
                fontSize: 30,
                opacity: 0.2,
              }}
            />
          }
        />
      </Stack>
      <Box display='flex' justifyContent='flex-end' gap={1} mt={4}>
        <Tooltip title='Refresh'>
          <Box>
            <M3IconButton onClick={handleOnRefreshClick}>
              <RefreshOutlinedIcon className={isRefreshing ? 'spinner' : ''} />
            </M3IconButton>
          </Box>
        </Tooltip>
        <Tooltip title='Download'>
          <Box>
            <M3IconButton
              disabled={isDownloading || isRefreshing}
              onClick={handleOnDownloadClick}
            >
              {isDownloading ? (
                <CircularProgress size={18} />
              ) : (
                <DownloadOutlinedIcon />
              )}
            </M3IconButton>
          </Box>
        </Tooltip>
      </Box>
    </Stack>
  );
};

export default Metadata;
