import React from 'react';
import {
  Box,
  FormControl,
  InputLabel,
  PaperProps,
  PopoverProps,
  Select,
  SelectProps,
  styled,
  SxProps,
} from '@mui/material';
import clsx from 'clsx';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';

import { M3MenuItem } from './M3MenuItem';
import BasicPopoverWithSearch, {
  M3OptionItem,
  useBasicPopover,
} from '../Popover/BasicPopoverWithSearch';

import { useAppProvider } from '../../providers/app/app';
import { ReactRenderElement } from '../../types/types';
import { M3AutocompleteProps } from './M3Autocomplete';

export const M3Select = styled(Select)`
  .MuiInputBase-root {
    padding-top: 0;
    padding-bottom: 0;
    box-sizing: border-box;
  }

  .MuiInputBase-input.MuiOutlinedInput-input,
  .MuiInputBase-input {
    height: 50px;
    font-size: 16px;
    display: block;
    box-sizing: border-box;
    padding-top: 14px;
  }

  .MuiInputLabel-root {
    background: transparent;
  }
`;

export const M3InputLabel = styled(InputLabel)`
  top: -3px;
  padding-left: 6px;
  padding-right: 6px;
  margin-left: -6px;
  background: var(--md-sys-color-on-primary-light);

  &.MuiInputLabel-shrink {
    transform: translate(14px, -5px) scale(0.75);
  }

  &.dark-mode-label {
    background: var(--md-sys-color-on-primary-dark);
  }
`;

type Menu = {
  id: string;
  label: string;
};
type M3SelectGroupProps = {
  formControlSx?: SxProps;
  label?: string;
  labelId?: string;
  selectProps?: SelectProps;
  options?: Menu[];
};

const autoFormOffProps: { [key: string]: 'off' | undefined } = {
  autoSave: 'off',
  autoCorrect: 'off',
  autoComplete: 'off',
  autoCapitalize: 'off',
};

export const M3SelectGroup = ({
  label,
  labelId,
  formControlSx,
  selectProps,
  options,
}: M3SelectGroupProps) => {
  const { isDarkMode } = useAppProvider();

  return (
    <FormControl sx={formControlSx}>
      {!!label && (
        <M3InputLabel
          id={labelId}
          className={clsx({
            'dark-mode-label': isDarkMode,
          })}
        >
          {label}
        </M3InputLabel>
      )}
      <M3Select labelId={labelId} {...autoFormOffProps} {...selectProps}>
        {options?.map((menu: Menu) => {
          return (
            <M3MenuItem key={menu.id} value={menu.id}>
              {menu.label}
            </M3MenuItem>
          );
        })}
      </M3Select>
    </FormControl>
  );
};

type M3DropdownProps = {
  disabled?: boolean;
  selected: M3OptionItem;
  options?: M3OptionItem[];
  onSelect?: (option: M3OptionItem) => void;
  paperProps?: PaperProps;
  popoverProps?: PopoverProps;
  className?: string;
  sx?: SxProps;
  withIcon?: boolean;
  contentSx?: SxProps;
  renderOption?: M3AutocompleteProps<M3OptionItem>['renderOption'];
  customRenderOption?: (props: {
    key: string | number;
    option: M3OptionItem;
    active: boolean;
    onSelect?: M3DropdownProps['onSelect'];
  }) => any;
};
export const M3Dropdown = ({
  disabled,
  selected,
  options = [],
  onSelect,
  paperProps,
  popoverProps,
  className,
  sx,
  withIcon = true,
  contentSx,
  renderOption,
  customRenderOption,
}: M3DropdownProps) => {
  const { isDarkMode } = useAppProvider();
  const popover = useBasicPopover();

  return (
    <>
      <Box
        display='flex'
        className={className}
        sx={{
          ...sx,
          ...(disabled
            ? {
                opacity: 0.5,
                pointerEvents: 'none',
              }
            : null),
        }}
      >
        <Box
          display='flex'
          alignItems='center'
          ref={popover.ref}
          onClick={popover.open}
          sx={{
            px: 0.2,
            py: 0.4,
            fontSize: 14,
            fontWeight: 500,
            borderRadius: 1,
            cursor: 'pointer',
            userSelect: 'none',
            background: isDarkMode
              ? 'var(--md-ref-palette-neutral-variant20)'
              : 'var(--md-ref-palette-neutral-variant90)',
            ...contentSx,
          }}
        >
          &nbsp;&nbsp;{selected.label}&nbsp;
          {withIcon ? (
            <ArrowDropDownOutlinedIcon
              style={{
                fontSize: 20,
                opacity: 0.5,
              }}
            />
          ) : (
            <>&nbsp;</>
          )}
        </Box>
      </Box>
      <BasicPopoverWithSearch
        withSearch={false}
        selected={selected}
        open={popover.isOpen}
        anchorEl={popover.ref.current}
        options={options}
        paperProps={paperProps}
        renderOption={renderOption}
        customRenderOption={customRenderOption}
        popoverProps={{
          ...popoverProps,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
            ...popoverProps?.anchorOrigin,
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
            ...popoverProps?.transformOrigin,
          },
        }}
        onSelect={(opt) => {
          onSelect?.(opt);
          popover.close();
        }}
        onClose={popover.close}
      />
    </>
  );
};
