import React, {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Box, Skeleton, Stack, Typography } from '@mui/material';
import moment from 'moment';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';

import FiltersBoard, { ProductivityFilterParams } from './FiltersBoard';
import DataTable from '../../components/DataTable/DataTable';
import MainContainer from '../../components/MainContainer';
import PageTitle from '../../components/Title/PageTitle';
import { M3Button } from '../../components/M3/M3Button';
import DocumentTitle from '../../components/DocumentTitle';

import { ProductivityAgentUser } from './types';
import { stripToFormatterWord, toTitleCase } from '../../utils/string';
import { useInfinite } from '../../hooks/global/useInfinite';
import {
  useUserAgentStatus,
  UserUserAgentStatusProps,
} from '../../hooks/go2-agent/user-status';
import { useUserProvider } from '../../providers/user/user';
import { UserActivitySkeleton } from '../../components/User/UserSkeleton';
import WindowScrollTop from '../../components/WindowScrollTop';
import UserTimeline from '../UserDetail/UserTimeline/UserTimeline';
import { useUserActivityPercentage } from '../../hooks/go2-agent/activities';
import { getWeekRangeStartsInMonday } from '../../utils/date';
import { useTimeTrackerWorklogs } from '../../hooks/time-tracker/worklogs';
import { useTimezone } from '../../components/Util/Timezone';

type Props = {};

const agentFieldLabelMap: {
  [K in keyof Partial<ProductivityAgentUser>]: string;
} = {
  id: 'ID',
  user_id: '',
  division_id: 'Division',
  // os: 'OS',
  // os_version: 'OS Version',
  // task_time: 'Last Login',
  task_name: 'Focus',
  go2_member_id: 'Activity %',
};

const agentFieldStyleMap: {
  [K in keyof Partial<ProductivityAgentUser>]: CSSProperties;
} = {
  id: {
    width: 100,
    minWidth: 100,
  },
  user_id: {
    flex: 1,
  },
};

const defaultAgentFields: (keyof ProductivityAgentUser)[] = [
  'user_id',
  // 'go2_member_id',
  // 'division_id',
  // 'task_name',
  // 'task_time',
  // 'os',
  // 'os_version',
  // 'go2_version',
  // 'status',
  // 'first_login',
];
const defaultActiveField: keyof ProductivityAgentUser = 'id';

const Users = (props: Props) => {
  const timezone = useTimezone();
  const { getUser, setUniqueIdsToFetch } = useUserProvider();
  const [filters, setFilters] = useState<{
    active: keyof ProductivityAgentUser;
    order: 'ASC' | 'DESC';
  }>({
    active: defaultActiveField,
    order: 'DESC',
  });
  const [isFiltersVisible, setIsFiltersVisible] = useState(true);
  const [dataTableKey, setDataTableKey] = useState(0);

  const [selectedFields, setSelectedFields] = useState<
    (keyof ProductivityAgentUser)[]
  >([]);

  const userTimelineRefProps = useRef<any>({});

  const handleColumnItem = useCallback(
    (field: keyof ProductivityAgentUser, item: ProductivityAgentUser) => {
      if (field === 'first_login' || field === 'task_time') {
        let d = moment.utc(item[field]).local();
        return item[field] ? (
          d.format('MMM D, YYYY hh:mma')
        ) : (
          <span style={{ opacity: 0.2 }}>–</span>
        );
      }

      if (field === 'user_id') {
        let user = getUser('user_id', item.user_id);

        return (
          <Box style={{ paddingTop: 4 }}>
            <Box display='flex' style={{ marginBottom: -14 }}>
              <UserActivitySkeleton
                user={user}
                linkProps={{
                  to: `/users/${user?.id}/activity`,
                  state: {
                    item,
                    user,
                  },
                }}
              />
            </Box>
            <br />
            <UserTimeline
              userId={item.user_id}
              withNavigationButtons={false}
              selectedFilteredDate={
                userTimelineRefProps.current.filterParams.start_date
              }
              {...userTimelineRefProps.current}
            />
          </Box>
        );
      }

      if (field === 'division_id') {
        let user = getUser('user_id', item.user_id);
        return user ? (
          user.division?.name || <span style={{ opacity: 0.2 }}>–</span>
        ) : (
          <Skeleton variant='text' />
        );
      }

      return item[field] ?? <span style={{ opacity: 0.2 }}>–</span>;
    },
    [getUser, userTimelineRefProps],
  );

  const handleHeaderColumnItem = useCallback(
    (field: keyof ProductivityAgentUser) => {
      let value =
        agentFieldLabelMap[field] ?? toTitleCase(stripToFormatterWord(field));
      return value;
    },
    [],
  );

  const handleOnHeaderColumnClick = useCallback(
    (field: keyof ProductivityAgentUser, order?: 'ASC' | 'DESC' | null) => {
      setFilters((filters) => {
        filters = { ...filters };
        if (filters.active === field) {
          filters.order = order ?? filters.order === 'ASC' ? 'DESC' : 'ASC';
        } else {
          filters.active = field;
          filters.order = order ?? 'DESC';
        }
        return filters;
      });
    },
    [setFilters],
  );

  const handleOnFieldsChange = useCallback(
    (newFields: (keyof ProductivityAgentUser)[]) => {
      setSelectedFields(
        newFields.length
          ? defaultAgentFields.filter((field) => newFields.includes(field))
          : [],
      );
      // Check if there's new fields selected
      // Check if the current filters is none on the list of fields
      if (newFields.length && !newFields.includes(filters.active)) {
        handleOnHeaderColumnClick(newFields[0]);
      }

      setDataTableKey((key) => ++key);
    },
    [filters, setSelectedFields, setDataTableKey, handleOnHeaderColumnClick],
  );

  /*
  const handleRowClick = useCallback(
    (item: AgentUser) => {
      navigate(`/users/${item.id}/activity`, {
        state: {
          item,
        },
      });
    },
    [navigate],
  );
  */

  // Filters --------------------------------------------------
  const [filterParams, setFilterParams] = useState<ProductivityFilterParams>(
    () => ({
      start_date: getWeekRangeStartsInMonday(moment()).start,
      end_date: getWeekRangeStartsInMonday(moment()).end,
      users: [],
      divisions: [],
    }),
  );
  const updateFilterParams = useCallback(
    (params: Partial<ProductivityFilterParams>) => {
      setFilterParams((state) => ({
        ...state,
        ...params,
      }));
    },
    [setFilterParams],
  );

  // User Agent Status ----------------------------------------------------------------
  const limit = 10;
  const agentStatusQueryParams: Partial<UserUserAgentStatusProps> = {
    user_ids: filterParams.users.map((u) => u.id).join(),
    division_ids: filterParams.divisions.map((div) => div.id).join(),
  };
  const infiniteUserAgentStatus = useInfinite<
    ProductivityAgentUser,
    UserUserAgentStatusProps
  >({
    keyFieldName: 'user_id',
    skipFetchOnInit: true,
    useQuery: useUserAgentStatus,
    queryParams: {
      user_ids: agentStatusQueryParams.user_ids,
      division_ids: agentStatusQueryParams.division_ids,
      limit: limit,
    },
    transformData: (item: ProductivityAgentUser) => {
      let taskTimeLocal = item.task_time && moment.utc(item.task_time).local();
      if (taskTimeLocal) {
        item.status =
          moment().diff(taskTimeLocal, 'minute') >= 2 ? 'online' : 'offline';
      }
      return item;
    },
  });

  // User Timeline Worklogs ----------------------------------------------------
  const startEndDateParams = {
    start_date: filterParams.start_date
      .clone()
      .subtract(1, 'day')
      .startOf('day')
      .utc()
      .format(),
    end_date: filterParams.start_date
      .clone()
      .add(1, 'day')
      .endOf('day')
      .utc()
      .format(),
  };
  const worklogsUserIdsParams = useMemo(
    () =>
      Array.from(
        new Set(infiniteUserAgentStatus.data.map((a) => a.user_id)),
      ).join(),
    [infiniteUserAgentStatus.data],
  );
  const { data: timeTrackerWorklogsDataSource } = useTimeTrackerWorklogs(
    {
      ...startEndDateParams,
      timezone,
      limit: 5000,
      users: worklogsUserIdsParams,
    },
    {
      // Refetch the interval every 4 minutes, 1 minute less between sync time on Go2 desktop
      // refetchInterval: 240 * 1000,
      // refetchOnWindowFocus: true,
      enabled: !!worklogsUserIdsParams.length,
    },
  );
  /**
   * Filtering the percentage data to only allow on memo
   */
  const timeTrackerWorklogsData = useMemo(() => {
    let items = timeTrackerWorklogsDataSource?.results ?? [];
    let ymdSelected = filterParams.start_date.format('YYYY-MM-DD');
    items = items.filter((item) => {
      let startLocal = moment.utc(item.start).local();
      let endLocal = moment.utc(item.end).local();
      // Check if the interval start/end local is same as selected date
      if (
        startLocal.format('YYYY-MM-DD') === ymdSelected ||
        endLocal.format('YYYY-MM-DD') === ymdSelected
      ) {
        return true;
      }
      return false;
    });
    return items;
  }, [timeTrackerWorklogsDataSource, filterParams.start_date]);

  // User Activity Percentage --------------------------------------------------
  const percentageUserIdsParams = useMemo(
    () =>
      Array.from(
        new Set(infiniteUserAgentStatus.data.map((a) => a.user_id)),
      ).join(),
    [infiniteUserAgentStatus.data],
  );
  const { data: userActivityPercentageDataSource } = useUserActivityPercentage(
    {
      ...startEndDateParams,
      user_ids: percentageUserIdsParams,
      limit: 5000,
    },
    {
      enabled: !!percentageUserIdsParams.length,
    },
  );
  /**
   * Filtering the percentage data to only allow on memo
   */
  const userActivityPercentageData = useMemo(() => {
    let items = userActivityPercentageDataSource ?? [];
    let ymdSelected = filterParams.start_date.format('YYYY-MM-DD');
    items = items.filter((item) => {
      let intervalStartLocal = moment.utc(item.interval_start).local();
      let intervalEndLocal = moment.utc(item.interval_end).local();
      // Check if the interval start/end local is same as selected date
      if (
        intervalStartLocal.format('YYYY-MM-DD') === ymdSelected ||
        intervalEndLocal.format('YYYY-MM-DD') === ymdSelected
      ) {
        return true;
      }
      return false;
    });
    return items;
  }, [userActivityPercentageDataSource, filterParams.start_date]);

  /**
   * User timeline props reference. To be used inside handleItem
   */
  userTimelineRefProps.current = {
    activityPercentageItems: userActivityPercentageData,
    worklogItems: timeTrackerWorklogsData,
    filterParams: filterParams,
  };

  useEffect(() => {
    setUniqueIdsToFetch({
      user_ids: infiniteUserAgentStatus.data.map((d) => d.user_id),
    });
  }, [infiniteUserAgentStatus.data, setUniqueIdsToFetch]);

  useEffect(() => {
    setUniqueIdsToFetch({
      user_ids: filterParams.users.map((d) => d.id),
    });
  }, [filterParams.users, setUniqueIdsToFetch]);

  useEffect(() => {
    infiniteUserAgentStatus.reset({
      emptyList: true,
    });
    // eslint-disable-next-line
  }, [filterParams]);

  return (
    <>
      <DocumentTitle title='Productivity' />
      <MainContainer sx={{ maxWidth: undefined }}>
        <Box
          display='flex'
          justifyContent='space-between'
          alignItems='flex-start'
        >
          <PageTitle title='Productivity' />
          <Stack direction='row' gap={2}>
            {/* <M3Button color='secondary'>Use Saved Filters</M3Button> */}
            <M3Button
              active={isFiltersVisible}
              onClick={() => setIsFiltersVisible(!isFiltersVisible)}
            >
              <FilterAltOutlinedIcon />
              {isFiltersVisible ? 'Hide' : 'Show'} Filters
            </M3Button>
          </Stack>
        </Box>
        <br />
        <Box display={isFiltersVisible ? undefined : 'none'}>
          <FiltersBoard
            filterParams={filterParams}
            selectedFields={selectedFields}
            allFields={defaultAgentFields}
            fieldLabelMap={agentFieldLabelMap}
            onFieldsChange={handleOnFieldsChange}
            updateFilterParams={updateFilterParams}
          />
        </Box>
        {/* <Metadata
          isDownloading={isDownloading}
          isRefreshing={infiniteUserAgentStatus.isLoading}
          totalOnline={totalOnline}
          totalUsers={infiniteUserAgentStatus.count}
          handleOnDownloadClick={handleOnDownloadUsers}
          handleOnRefreshClick={handleOnRefreshClick}
        /> */}
        <Typography component='div'>
          <DataTable
            key={dataTableKey}
            orderBy={filters.order}
            // activeField={filters.active}
            fields={selectedFields.length ? selectedFields : defaultAgentFields}
            data={infiniteUserAgentStatus.data}
            fieldColumnStyleMap={agentFieldStyleMap}
            handleColumnItem={handleColumnItem}
            handleHeaderColumnItem={handleHeaderColumnItem}
            // handleOnHeaderColumnClick={handleOnHeaderColumnClick}
            // handleRowClick={handleRowClick}
          />
        </Typography>
      </MainContainer>
      {/* <InfiniteScrollListener
        onReachBottom={() => infiniteUserAgentStatus.loadNext()}
      /> */}
      <WindowScrollTop />
    </>
  );
};

export default Users;
