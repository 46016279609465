import { faker } from '@faker-js/faker';
import moment from 'moment';

export const generateActivityApplicationItems = (
  count: number,
): ActivityApplicationItem[] => {
  let initialTodayUTC = moment.utc().startOf('day');

  return Array.from({ length: count }, (_, index) => ({
    id: (index + 1).toString(),
    name: faker.person.fullName(),
    exec: faker.person.fullName(),
    user_id: index + 1,
    start: initialTodayUTC.format(),
    end: initialTodayUTC.add(Math.ceil(Math.random() * 20), 'minute').format(),
    timestamp: initialTodayUTC
      .add(Math.ceil(Math.random() * 20), 'minute')
      .format(),
    email: faker.internet.email().toLowerCase(),
    division: faker.commerce.department(),
    computer: faker.internet.domainWord(),
    domain: faker.internet.domainName(),
    duration: faker.date
      .recent()
      .toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: true,
      })
      .toLowerCase()
      .replace(/\s+(am|pm)$/, '')
      .split(':')
      .map((v, i) => v + (i === 0 ? 'h' : i === 1 ? 'm' : 's'))
      .join(' '),
    title: faker.lorem.sentence(),
    url: faker.internet.url(),
    os: 'macOS',
    sync: 0,
  }));
};

export type UserActivity = {
  id: string;
  title: string;
  url: string;
  os: 'macOS' | 'windows';
  name: string;
  exec: string;
  timestamp: string;
  sync: number;
  user_id: number;
  start: string;
  end: string;
};

export type ActivityApplicationItem = UserActivity & {
  email: string;
  division: string;
  computer: string;
  domain: string;
  duration: string; // hh:mm:ssa
};
