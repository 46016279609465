import { UseQueryOptions } from '@tanstack/react-query';
import { useIdentifier } from '../global/useIdentifier';
import { useMutationApi, useQueryApi } from '../global/useApi';
import { ListResult } from '../../types/response';
import { defaultReactQueryParams } from '../../utils/request';
import { AxiosRequestConfig } from 'axios';
import { Moment } from 'moment';
import { ListQuery } from '../../types/request';
import { DateInUTC } from '../../types/types';
import { TimeTrackerWorklogItem } from '../../types/time-tracker-worklogs';

export type UseTimeTrackerWorklogsProps = ListQuery & {
  start_date?: string;
  end_date?: string;
  timezone?: string;
  users?: string;
};
export function useTimeTrackerWorklogs<R = ListResult<TimeTrackerWorklogItem>>(
  params: UseTimeTrackerWorklogsProps,
  reactQueryParams?: UseQueryOptions<R, Error, R>,
) {
  const { identifiers, queryParams } =
    useIdentifier<UseTimeTrackerWorklogsProps>({
      params,
      baseIdentifier: 'useFetchTimeDetails',
      identifierKeys: ['start_date', 'end_date', 'timezone', 'users'],
      queryParamKeys: ['start_date', 'end_date', 'timezone', 'users'],
    });

  return useQueryApi<UseTimeTrackerWorklogsProps, R>(
    identifiers,
    '/time-tracker/worklogs/',
    queryParams,
    { ...defaultReactQueryParams, ...reactQueryParams },
  );
}
