import React, { memo, useMemo, useRef } from 'react';
import { Box, Stack, Tooltip } from '@mui/material';
import MonitorOutlinedIcon from '@mui/icons-material/MonitorOutlined';
import moment, { Moment } from 'moment';
import { getDurationSeconds } from '../../../utils/date';
import { BiActivityIntervalItem } from '../../../types/activity';

type Props = {
  items: BiActivityIntervalItem[];
  dimensions: { width: number; height: number };
  selectedFilteredDate: Moment;
};

type BiActivityIntervalItemWithLocal = BiActivityIntervalItem & {
  start_local: Moment;
  end_local: Moment;
};

const ActivityStack = ({ items = [], selectedFilteredDate }: Props) => {
  const stackRef = useRef<HTMLDivElement | null>(null);
  let totalHoursToday = 24 * 60 * 60;

  const itemsWithLocal: BiActivityIntervalItemWithLocal[] = useMemo(() => {
    let itemsLocal: BiActivityIntervalItemWithLocal[] = [];
    let ymdSelected = selectedFilteredDate.format('YYYY-MM-DD');

    items.forEach((item) => {
      let start_local = moment.utc(item.interval_start).local();
      let end_local = moment.utc(item.interval_end).local();
      let ymdStart = start_local.format('YYYY-MM-DD');
      let ymdEnd = end_local.format('YYYY-MM-DD');

      // If both start/end is on the same selected local date.
      // We push it immediately.
      if (ymdStart === ymdSelected && ymdEnd === ymdSelected) {
        itemsLocal.push({
          ...item,
          start_local,
          end_local,
        });
      }
      // If the start date is on the previous day, and its end is on the the
      // same as selected. We will have the start to be at 12:00am
      else if (
        moment(ymdStart).isBefore(ymdSelected) &&
        ymdEnd === ymdSelected
      ) {
        itemsLocal.push({
          ...item,
          start_local: moment(ymdEnd).startOf('day'),
          end_local,
        });
      }
      // If the end date is on the next day, and its start is on the the
      // same as selected. We will have the end to be at 11:59:59pm.
      else if (
        moment(ymdEnd).isAfter(ymdSelected) &&
        ymdStart === ymdSelected
      ) {
        itemsLocal.push({
          ...item,
          start_local,
          end_local: moment(ymdStart).endOf('day'),
        });
      }
    });

    return itemsLocal;
  }, [items, selectedFilteredDate]);

  return (
    <Box display='flex' alignItems='center' flex={1}>
      <Box
        display='flex'
        alignItems='center'
        style={{
          width: 40,
          marginLeft: -40,
        }}
      >
        <MonitorOutlinedIcon style={{ fontSize: 20, opacity: 0.8 }} />
      </Box>
      <Stack
        ref={stackRef}
        direction='row'
        style={{
          width: '100%',
          height: '100%',
          position: 'relative',
        }}
      >
        {itemsWithLocal.map((item, index, arr) => {
          let width = stackRef.current?.clientWidth ?? 0;

          let startDateLocal = item.start_local;
          let endDateLocal = item.end_local;
          let duration = getDurationSeconds(endDateLocal, startDateLocal);
          let durationStartOfDayToStartTime = getDurationSeconds(
            startDateLocal,
            startDateLocal.clone().startOf('day'),
          );
          let durationStartOfDayToEndOfTime =
            durationStartOfDayToStartTime + duration;

          let startPct = durationStartOfDayToStartTime / totalHoursToday;
          let endPct = durationStartOfDayToEndOfTime / totalHoursToday;
          let diffPct = endPct - startPct;
          let startPos = width * startPct;
          let endPos = width * diffPct;

          let pct = parseFloat((item.activity_percentage * 100).toFixed(2));

          const tooltipTitle = (
            <>
              <span className='tooltip-duration'>{pct}%</span>
              <br />
              <span className='tooltip-task-name'>{item.focus}</span>
              <br />
              <span className='tooltip-start-end'>
                {startDateLocal.format('hh:mm A')} -{' '}
                {endDateLocal ? endDateLocal.format('hh:mm A') : 'ongoing'}
              </span>
            </>
          );

          let bgColor = '';
          if (pct >= 70) {
            bgColor = '#51b7ad';
          } else if (pct >= 11 && pct < 70) {
            bgColor = '#e7e07f';
          } else {
            bgColor = '#c01d0c';
          }

          return (
            <Box
              key={index}
              style={{
                top: 0,
                left: startPos,
                width: endPos,
                height: '100%',
                position: 'absolute',
                background: bgColor,
              }}
            >
              <Tooltip title={tooltipTitle}>
                <Box
                  style={{
                    height: '100%',
                  }}
                />
              </Tooltip>
            </Box>
          );
        })}
      </Stack>
    </Box>
  );
};

export default ActivityStack;
