import React, {
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { AutocompleteValue, Box, Stack, Typography } from '@mui/material';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import clsx from 'clsx';

import { M3Autocomplete } from '../M3/M3Autocomplete';
import { M3TextField } from '../M3/M3TextField';
import { M3OptionItem } from '../Popover/BasicPopoverWithSearch';

import { useDebounce } from '../../hooks/utils/misc';
import { useInfinite, UseInfiniteProps } from '../../hooks/global/useInfinite';
import { sortBy } from '../../utils/array';
import { escapeRegExp } from '../../utils/string';
import { useAppProvider } from '../../providers/app/app';
import { ReactRenderElement } from '../../types/types';
import './AutocompleteSearch.css';

type Props = {
  searchKey?: string;
  multiple?: boolean;
  label?: string;
  placeholder?: string;
  withSearchIcon?: boolean;
  onSelect?: (
    option?:
      | AutocompleteSearchOptionItem
      | AutocompleteSearchOptionItem[]
      | null,
  ) => void;
  optionFormatter: <T = unknown>(opt: T) => AutocompleteSearchOptionItem;
  useInfiniteProps: UseInfiniteProps<unknown, unknown>;
  onSearchTextOptions?: (regex: RegExp) => AutocompleteSearchOptionItem[];
  renderOptionItem?: (props: SearchResultProps) => ReactRenderElement;
  defaultOptionsSelected?: AutocompleteSearchOptionItem[];
  orderGroupBy?: 'ASC' | 'DESC';
  submitOnEnter?: boolean;
};

export type AutocompleteSearchOptionItem<P = any> = M3OptionItem<
  P & {
    multiple?: boolean;
  }
>;

const AutocompleteSearch = ({
  searchKey = 'search',
  multiple,
  label,
  placeholder = 'Search division...',
  withSearchIcon,
  onSelect,
  optionFormatter,
  useInfiniteProps,
  onSearchTextOptions,
  renderOptionItem,
  defaultOptionsSelected,
  orderGroupBy = 'ASC',
  submitOnEnter,
}: Props) => {
  const { isDarkMode } = useAppProvider();

  const [text, setText] = useState('');
  const [selected, setSelected] = useState<AutocompleteSearchOptionItem | null>(
    null,
  );
  const [optionsSelected, setOptionsSelected] = useState<
    AutocompleteSearchOptionItem[]
  >(defaultOptionsSelected ?? []);

  const search = useDebounce(text);
  const searchInfinite = useInfinite<any, any>({
    ...useInfiniteProps,
    skipFetchOnInit: true,
    queryParams: {
      ...useInfiniteProps.queryParams,
      [searchKey]: search || 'a',
    },
  });

  const autocompleteOptions: AutocompleteSearchOptionItem[] = useMemo(() => {
    let options: AutocompleteSearchOptionItem[] =
      searchInfinite.data.map(optionFormatter);

    if (search) {
      const regex = new RegExp(escapeRegExp(search), 'i');
      if (onSearchTextOptions) {
        let opts = onSearchTextOptions(regex);
        options.push(...opts);
      }
    }

    options = sortBy<AutocompleteSearchOptionItem>(
      options,
      'label',
      true,
      orderGroupBy,
    ).filter((o) => !optionsSelected.some((us) => us.id === o.id));

    if (optionsSelected.length) {
      let us = sortBy<AutocompleteSearchOptionItem>(
        optionsSelected,
        'label',
        true,
        orderGroupBy,
      );
      options = [
        ...us.map(
          (o) =>
            ({
              ...o,
              props: {
                ...o.props,
                multiple: true,
              },
            } as AutocompleteSearchOptionItem),
        ),
        ...options,
      ];
    }

    return options;
  }, [
    searchInfinite.data,
    search,
    optionsSelected,
    optionFormatter,
    onSearchTextOptions,
    orderGroupBy,
  ]);

  const handleOnTextChange = useCallback(
    (evt: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const input = evt.currentTarget;
      setText(input.value);
      setSelected(null);
    },
    [setText, setSelected],
  );

  const handleOnSelect = useCallback(
    (
      evt: SyntheticEvent,
      option: AutocompleteValue<AutocompleteSearchOptionItem, any, any, any>,
    ) => {
      if (multiple) {
        let options = [...optionsSelected];
        if (option && (option as AutocompleteSearchOptionItem).id) {
          let u = options.find(
            (u) => u.id === (option as AutocompleteSearchOptionItem).id,
          );
          // Check if option already exist, then don't include it on the list
          if (u) {
            options = options.filter((us) => us.id !== u!.id);
          } else {
            options.push(option as AutocompleteSearchOptionItem);
          }
        }

        setText('');
        setOptionsSelected(options);
        onSelect?.(options);
      } else {
        setText(
          ((option as AutocompleteSearchOptionItem) || null)?.label ?? '',
        );
        setSelected((option as AutocompleteSearchOptionItem) || null);
        onSelect?.((option as AutocompleteSearchOptionItem) || null);
      }
    },
    [
      multiple,
      optionsSelected,
      setText,
      setSelected,
      setOptionsSelected,
      onSelect,
    ],
  );

  useEffect(() => {
    searchInfinite.reset({
      emptyList: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  return (
    <Box
      position='relative'
      sx={{
        '.MuiAutocomplete-root .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
          {
            borderColor: `${
              isDarkMode
                ? 'var(--md-ref-palette-neutral-variant20)'
                : 'var(--md-ref-palette-neutral80)'
            } !important`,
          },
      }}
    >
      {withSearchIcon && (
        <SearchOutlinedIcon
          sx={{
            top: 13,
            left: 14,
            zIndex: 2,
            opacity: 0.4,
            position: 'absolute',
          }}
        />
      )}
      <M3Autocomplete
        options={autocompleteOptions}
        loading={searchInfinite.isLoading}
        disableCloseOnSelect={multiple}
        inputValue={text}
        clearIcon={text ? undefined : null}
        onChange={handleOnSelect}
        noOptionsText={
          searchInfinite.isLoading ? 'Searching...' : 'No results found'
        }
        renderInput={(params) => (
          <M3TextField
            {...params}
            label={label}
            fullWidth
            focused
            placeholder={placeholder}
            onChange={handleOnTextChange}
            onKeyUp={
              submitOnEnter
                ? (evt) => {
                    let key = evt.key;
                    let value = (evt.target as HTMLInputElement).value.trim();

                    if (key === 'Enter' && value) {
                      handleOnSelect(null as any, {
                        id: value,
                        label: value,
                        props: {
                          custom: true,
                          value,
                        },
                      });
                    }
                  }
                : undefined
            }
          />
        )}
        renderOption={(props, option: AutocompleteSearchOptionItem) => (
          <li
            {...props}
            key={option.id}
            className={clsx(props.className, {
              'autocomplete-multiple-item-selected': option.props?.multiple,
            })}
          >
            {renderOptionItem ? (
              renderOptionItem({
                multiple,
                option,
                selected: multiple && option.props?.multiple,
              })
            ) : (
              <SearchResult
                multiple={multiple}
                option={option}
                selected={multiple && option.props?.multiple}
              />
            )}
          </li>
        )}
        sx={{
          flex: 1,
          '.MuiInputBase-input': {
            paddingLeft: withSearchIcon ? '38px !important' : '6px !important',
          },
          '.MuiOutlinedInput-notchedOutline': {
            borderWidth: '1px !important',
          },
        }}
      />
    </Box>
  );
};

export default AutocompleteSearch;

export type SearchResultProps = {
  multiple?: boolean;
  selected?: boolean;
  option: AutocompleteSearchOptionItem;
};
function SearchResult({ selected, multiple, option }: SearchResultProps) {
  return (
    <Stack
      gap={1}
      py={0.5}
      flex={1}
      flexDirection='row'
      alignItems='center'
      position='relative'
    >
      <Box flex={1} width={0}>
        <Typography
          component='div'
          fontSize={14}
          fontWeight={500}
          minWidth={70}
          lineHeight={1.2}
        >
          {option.label}
        </Typography>
      </Box>
      {multiple && selected && (
        <CheckOutlinedIcon
          style={{
            opacity: 0.5,
            fontSize: 16,
            marginRight: -4,
          }}
        />
      )}
    </Stack>
  );
}
