import React, { useMemo } from 'react';
import { Moment } from 'moment';
import { Box } from '@mui/material';

import { useModal } from '../../../components/M3/M3Modal';
import TimelineBlock from './TimelineBlock';

import { generateActivityApplicationItems } from '../../Activities/types';
import { generateUserKeystrokes } from '../types';
import { UserDetailFilterParams } from './_types';
import { TimeTrackerWorklogItem } from '../../../types/time-tracker-worklogs';
import { BiActivityIntervalItem } from '../../../types/activity';

type Props = {
  userId?: number;
  filterParams: UserDetailFilterParams;
  selectedFilteredDate: Moment;
  prevNextSelectedFilteredDate: (action: 'next' | 'previous') => void;
  worklogItems?: TimeTrackerWorklogItem[];
  activityPercentageItems?: BiActivityIntervalItem[];
  withNavigationButtons?: boolean;
};

const UserTimeline = ({
  userId,
  filterParams,
  selectedFilteredDate,
  prevNextSelectedFilteredDate,
  worklogItems = [],
  activityPercentageItems = [],
  withNavigationButtons,
}: Props) => {
  const modal = useModal();
  const activityItems = useMemo(
    () => generateActivityApplicationItems(100),
    [],
  );
  const keystrokeItems = useMemo(() => generateUserKeystrokes(100), []);

  const worklogItemsForUser = useMemo(
    () =>
      userId ? worklogItems.filter((w) => w.user === userId) : worklogItems,
    [userId, worklogItems],
  );
  const activityPercentageItemsForUser = useMemo(
    () =>
      userId
        ? activityPercentageItems.filter((a) => a.user_id === userId)
        : activityPercentageItems,
    [userId, activityPercentageItems],
  );

  return (
    <>
      {/* <Box display='flex' justifyContent='center'>
        <M3Button color='primary' variant='contained' onClick={modal.open}>
          View Activity Playback
        </M3Button>
      </Box> */}
      <Box>
        <Box
          display='flex'
          flexDirection='row'
          style={
            {
              // minHeight: '30vh',
              // background: 'red',
              // position: 'relative',
            }
          }
        >
          {/* <TimelineSidebar /> */}
          <TimelineBlock
            withNavigationButtons={withNavigationButtons}
            activityItems={activityItems}
            keystrokeItems={keystrokeItems}
            worklogItems={worklogItemsForUser}
            activityPercentageItems={activityPercentageItemsForUser}
            filterParams={filterParams}
            selectedFilteredDate={selectedFilteredDate}
            prevNextSelectedFilteredDate={prevNextSelectedFilteredDate}
          />
        </Box>
      </Box>
      {/* <UserTimelineModal open={modal.isOpen} onClose={modal.close} /> */}
      {/* <UserPlaybackModal open={modal.isOpen} onClose={modal.close} /> */}
    </>
  );
};

export default UserTimeline;
